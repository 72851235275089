<template>
  <b-modal
      id="modal-payment-update"
      title="Редактирование платежа"
      ok-title="Сохранить"
      @ok="update"
      no-close-on-backdrop
      cancel-title="Отмена"
      cancel-variant="outline-secondary"
  >
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label>Тип контрагента</label>
          <v-select v-model="selectedType" :clearable="false" :options="types" @input="updateType"/>
        </div>
      </div>
      <div class="col-8">
        <div class="form-group">
          <label>Тип платежа</label>
          <v-select
              v-model="form.direction"
              :clearable="false"
              :options="selectedType && paymentTypes[selectedType.value]"
          />
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Касса</label>
      <v-select
          v-model="form.money_account"
          :options="bankAccounts"
          :clearable="false"
          label="name"
      >
      </v-select>
    </div>
    <div class="form-group">
      <label>{{ selectedType && selectedType.value === 'client' ? 'Клиент' : 'Поставщик' }}</label>
      <v-select
          v-model="form.user"
          :options="users"
          label="surname"
          :get-option-label="(option) => option.name + ' ' + option.surname"
      >
        <template #option="{ name, surname, company_name }">
          <span> {{ name }} {{ surname }} {{ company_name && `(${company_name})` }}</span>
        </template>
      </v-select>
    </div>
    <div class="row">
      <div class="col-8">
        <div class="form-group">
          <label>Сумма</label>
          <input class="form-control" v-model="form.amount">
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label>Валюта</label>
          <v-select
              v-model="form.currency"
              :options="currencies"
              :clearable="false"
              label="name"
          >
          </v-select>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Описание платежа</label>
      <textarea class="form-control" v-model="form.comments">
      </textarea>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'PaymentUpdateModal',
  props: ['id'],
  components: {
    BModal, vSelect,
  },
  data() {
    return {
      selectedType: null,
      types: [
        { value: 'client', label: 'Клиент' },
        { value: 'supplier', label: 'Поставщик' },
      ],
      paymentTypes: {
        client: [
          { value: 'in', label: 'Оплата клиентом' },
          { value: 'out', label: 'Возврат денег клиенту' },
        ],
        supplier: [
          { value: 'in', label: 'Возврат денег поставщиком' },
          { value: 'out', label: 'Оплата поставщику' },
        ],
      },
      form: {
        direction: null,
        user: null,
        amount: null,
        currency: null,
        money_account: null,
      },
      users: [],
      currencies: [],
      bankAccounts: [],
    };
  },
  watch: {
    async id() {
      await this.getPaymentInfo();
    },
  },
  async mounted() {
    await this.getCurrencies();
    await this.getMoneyAccounts();
    this.selectedType = { ...this.types[0] };
    await this.updateType();
  },
  methods: {
    async getPaymentInfo() {
      const response = await this.$api.payments.get(this.id);
      this.selectedType = this.types.find((type) => type.value === response.data.type);
      this.form.direction = this.paymentTypes[this.selectedType.value].find((type) => type.value === response.data.direction);
      this.form.amount = response.data.sum;
      this.form.comments = response.data.comments;
      this.form.user = this.users.find((user) => user.id === response.data.user_id);
      this.form.currency = this.currencies.find((currency) => currency.id === response.data.currency_id);
      this.from.money_account = this.bankAccounts.find((account) => account.id === response.data.money_account_id);
    },
    async update() {
      await this.$api.payments.update(this.id, this.form);
      this.$emit('update');
    },
    async updateType() {
      this.form.direction = { ...this.paymentTypes[this.selectedType.value][0] };
      await this.getUsers();
    },
    async getUsers() {
      if (this.selectedType.value === 'client') {
        this.users = (await this.$api.users.list()).data;
      } else {
        this.users = (await this.$api.users.suppliers()).data;
      }
    },
    async getCurrencies() {
      this.currencies = (await this.$api.currencies.list()).data;
      this.form.currency = this.currencies.find((currency) => currency.id === 2);
    },
    async getMoneyAccounts() {
      this.bankAccounts = (await this.$api.bankAccounts.list()).data;
      this.form.money_account = this.bankAccounts.find((account) => account.id === 9);
    },
  },
};
</script>

<style scoped>

</style>
