<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <b-card no-body>
            <div class="m-2">
            </div>
            <div class="mx-2 mb-2">
              <div class="row mb-2">
                <div class="col-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <button
                        v-b-modal.modal-payment-create class="btn btn-primary"
                        v-if="$can('create', 'payments')"
                    >
                      Добавить Платеж
                    </button>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ payments.from }} по {{ payments.to }} из {{ payments.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="payments.total"
                      :per-page="payments.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
            <div class="position-relative table-responsive">
              <table class="table b-table">
                <thead>
                <tr>
                  <th>#</th>
                  <th>Дата</th>
                  <th v-if="$can('read', 'users')">Клиент</th>
                  <th>Счет</th>
                  <th>Сумма</th>
                  <th>Статус</th>
                  <th>Комментарий</th>
                  <th colspan="2">Действия</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="payment in payments.data" :key="payment.id">
                  <td class="font-weight-bold">#{{ payment.id }}</td>
                  <td>{{ $formatters.formattedDate(payment.created_at) }}</td>
                  <td v-if="$can('read', 'users')">{{ payment.user.name }} {{ payment.user.surname }} <br>
                    <small>({{ payment.user.company_name }})</small>
                  </td>
                  <td>{{ payment.payment_account.name }}</td>
                  <td>
                    <span :class="['amount', ['in', 'insupplier'].includes(payment.direction) ? 'positive' : 'negative']">
                      {{ payment.sum }}
                    </span>
                    <span class="currency">{{ payment.currency.name }}</span>
                  </td>
                  <td>
                    <b-avatar :id="`payment-row-${payment.id}`" size="32" :variant="`light-success`">
                      <feather-icon :icon="`SendIcon`"/>
                    </b-avatar>
                    <b-tooltip :target="`payment-row-${payment.id}`" placement="top">
                      <p class="mb-0">Оплачено</p>
                    </b-tooltip>
                  </td>
                  <td :class="payment.comments && payment.comments.length > 25 && 'font-small-2'">
                    <span :class="payment.comments && payment.comments.length > 20 && 'text-dashed'">
                      {{ payment.comments && payment.comments.length > 25 ? payment.comments.substring(0, 25) + '...' : payment.comments }}
                    </span>
                  </td>
                  <td>
                    <button
                        v-if="$can('manage', 'payments')"
                        @click="openUpdateModal(payment.id)"
                        class="btn btn-primary"
                    >
                      Редактировать
                    </button>
                  </td>
                  <td>
                    <button
                        v-if="$can('manage', 'payments')"
                        class="btn btn-danger"
                        @click="deletePayment(payment.id)"
                    >
                      Удалить
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="mx-2 mb-2">
              <div class="row">
                <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ payments.from }} по {{ payments.to }} из {{ payments.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="payments.total"
                      :per-page="payments.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
            </div>
          </b-card>
        </transition>
      </div>
    </div>
    <PaymentCreateModal @update="getPayments"/>
    <PaymentUpdateModal :id="selectedPaymentId" @update="getPayments"/>
  </div>
</template>

<script>
import {
  BCard, BFormInput, BButton, BAvatar, BPagination, BTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import PaymentCreateModal from '@/views/payments/PaymentCreateModal.vue';
import PaymentUpdateModal from '@/views/payments/PaymentUpdateModal.vue';

export default {
  name: 'Payments',
  components: {
    BCard, BFormInput, BButton, BAvatar, BPagination, BTooltip, vSelect, PaymentCreateModal, PaymentUpdateModal,
  },
  data() {
    return {
      currentPage: 1,
      statuses: [],
      payments: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
      },
      selectedPaymentId: null,
    };
  },
  watch: {
    async currentPage(newValue) {
      this.payments.current_page = newValue;
      await this.getPayments();
    },
  },
  async mounted() {
    await this.getPayments();
  },
  methods: {
    async getPayments() {
      this.payments = (await this.$api.payments.list({
        ...(this.payments.current_page > 1 && { page: this.payments.current_page }),
        limit: 25,
      })).data;
    },
    openUpdateModal(id) {
      this.selectedPaymentId = id;
      this.$bvModal.show('modal-payment-update');
    },
    async deletePayment(id) {
      this.$swal({
        title: 'Вы уверены?',
        text: 'Вы не сможете это вернуть назад!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да, подтверждаю!',
        cancelButtonText: 'Нет, отмена!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          await this.$api.payments.delete(id);
          await this.getPayments();
          this.$swal({
            icon: 'success',
            title: 'Удалено!',
            text: 'Платеж был успешно удален.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

span.amount {
  font-size: 18px;
}
.positive {
  color: $success;
}
.negative {
  color: $danger;
}
span.currency {
  font-size: 10px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
